import * as React from "react";
import withSessionContext from "HOC/withSessionContext";
import { Persist } from "react-persist";
import { urls } from "HOC/withApiService";
const qs = require("query-string");
const WorkspaceContext = React.createContext();
export const WorkspaceContextConsumer = WorkspaceContext.Consumer;

let initialState = {
  page: "Workspace",
  quizQnAnsData: [],
  workspaceData: {},
  studentGroupData: {},
  tracking_res_id: 0,
  startLessonId: 0,
  isSharedAssignment: false,
  tab: "assignment",
  prebuiltLang: "English",
  assignmentModalData: {
    groupsData: [],
    selectedCategory: 1,
    selectedClass: "",
    initialUnassignedList: [],
    initialAssignedList: [],
    initialAssignedTakenList: [],
    currentUnassignedList: [],
    currentAssignedList: [],
    fromDate: "",
    toDate: "",
  },
  fullGroupsData: [],
  isLessonUrlnotValid: false,
  shareLessonData: "",
};

class WorkspaceContextProvider extends React.Component {
  state = {
    ...initialState,

    _setState: (next, cb) => {
      //Call this methos from any child component to update the Workspace state
      if (typeof next === "function") {
        this.setState(
          (state) => next(state),
          () => {
            cb && typeof cb === "function" && cb(this.state);
            if (
              !process.env.NODE_ENV ||
              process.env.NODE_ENV === "development"
            ) {
              console.log("-------new Workspace state:", this.state);
            }
          }
        );
      } else {
        this.setState({ ...next }, () => {
          cb && typeof cb === "function" && cb(this.state);
          if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
            console.log("-------new Workspace state:", this.state);
          }
        });
      }
    },

    resetAssignmentModalData: () => {
      this.setState((state) => ({
        ...state,
        assignmentModalData: {
          groupsData: [],
          selectedCategory: 1,
          selectedClass: "",
          initialUnassignedList: [],
          initialAssignedList: [],
          initialAssignedTakenList: [],
          currentUnassignedList: [],
          currentAssignedList: [],
          fromDate: "",
          toDate: "",
        },
        fullGroupsData: [],
      }));
    },

    resetState: () => {
      this.setState(
        (state) => ({
          ...initialState,
        }),
        () => {
          console.log("--------Workspace context reset:", this.state);
        }
      );
    },
    lesson_player: async (lesson_id) => {
      const { post, userData } = this.props.sessionContext;
      try {
        let res = await post({
          method: "GET",
          url: `${urls.workspace.lesson_player}?user_puid=${userData.userPuid}&lesson_id=${lesson_id}&assignment_id=${this.state.startLessonId}`,
          data: undefined,
        });

        if (res) {
          // res = JSON.parse(res);
          // showNotification({ message: res.status, type: "success" });
          this.state._setState({
            ...this.state,
            lessonData: res.data,
          });
          this.props.sessionContext.stopSpinner();
          return res;
        } else {
          this.props.sessionContext.stopSpinner();
          throw "lesson player error";
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.props.sessionContext.stopSpinner();
      }
    },
    track_lesson: async (data) => {
      const { post, showNotification, userData } = this.props.sessionContext;

      try {
        let res = await post({
          url: `${urls.workspace.track_lesson}?user_puid=${userData.userPuid}&lesson_id=${data.lesson_id}&assignment_id=${this.state.startLessonId}&time_spent=${data.time_spent}&tracking_id=${data.tracking_id}&mode=${data.mode}`,
        });

        if (res) {
          if (res.status === "success") {
            this.state._setState((state) => ({
              ...state,
              tracking_id: res.data,
              tracking_res_id: 0,
            }));
          }
        } else {
          throw "lesson player track error";
        }
      } catch (e) {
        console.log(e);
      }
    },
    trackLessonResource: async (data) => {
      const { post, userData } = this.props.sessionContext;

      try {
        let res = await post({
          url: `${urls.workspace.track_lesson_resource}?user_puid=${userData.userPuid}&time_spent=${data.time_spent}&percentage_completed=0&previous_resource_id=${data.previous_resource_id}&&resource_id=${data.resource_id}&tracking_id=${data.tracking_id}&lesson_tracking_id=${data.lesson_tracking_id}&lesson_id=${data.lesson_id}&resource_type_id=${data.resource_type_id}&assignment_id=${this.state.startLessonId}`,
        });

        if (res) {
          // res = JSON.parse(res);
          if (res.status === "success") {
            this.state._setState((state) => ({
              ...state,
              tracking_res_id: res.data,
            }));
          }
        } else {
          throw "lesson player track error";
        }
      } catch (e) {
        console.log(e);
      }
    },
    quizOverView: async (data, lessonId, isPreview, isAssessment) => {
      const { post, userData } = this.props.sessionContext;
      const quizId = data;
      let updatedURL;
      // if (isPreview) {
      //   updatedURL = `${
      //     isAssessment
      //       ? urls.assessment.assessment_overview
      //       : urls.workspace.quiz_overview
      //   }?user_puid=${userData.userPuid}&assignment_id=0&lesson_id=0&${
      //     isAssessment ? "assessment_id" : "quiz_id"
      //   }=${quizId}`;
      // } else {
      updatedURL = `${
        isAssessment
          ? urls.assessment.assessment_overview
          : urls.workspace.quiz_overview
      }?user_puid=${userData.userPuid}&lesson_resource_tracking_id=${
        this.state.tracking_res_id
      }&lesson_id=${lessonId}&${
        isAssessment ? "assessment_id" : "quiz_id"
      }=${quizId}`;
      // }
      // try {
      let res = await post({
        method: "GET",
        url: updatedURL,
        data: undefined,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            quizQnAnsData: res.data.quiz_details,
          }));
          return res;
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "lesson player track error";
      }
      // } catch (e) {
      //   console.log(e);
      // }
    },
    StartQuiz: async (data, isAssessment, isLeanosity) => {
      const { post, userData } = this.props.sessionContext;
      const quizId = data.quiz_id;
      let updatedURL;
      if (isAssessment) {
        updatedURL = `${urls.assessment.start_assessment}?assessment_id=${quizId}&is_learnosity_enabled=${isLeanosity}`;
      } else {
        updatedURL = `${urls.workspace.StartQuiz}?quiz_id=${quizId}`;
      }
      try {
        let res = await post({
          url: `${updatedURL}&user_puid=${userData.userPuid}&lesson_resource_tracking_id=${this.state.tracking_res_id}&lesson_id=${data.lesson_id}`,
        });

        if (res) {
          if (res.status === "success") {
            this.state._setState((state) => ({
              ...state,
              startQuizData: res,
            }));
          } else {
            throw res.message;
          }
        } else {
          throw "lesson player track error";
        }
      } catch (e) {
        console.log(e);
      }
    },
    submitQuiz: async (type) => {
      const { post } = this.props.sessionContext;
      const { startQuizData = {}, quizQnAnsData } = this.state;

      let finalSendData = {};
      quizQnAnsData.map((data, key) => {
        if (data.answeredOption && data.answeredOption >= 0) {
          return (finalSendData[data.id] = {
            answer_text: data.answer_options[data.answeredOption].answer_text,
            answer_id: data.answer_options[data.answeredOption].id,
            is_correct_answer:
              data.answer_options[data.answeredOption].is_answer,
            time_spent: this.props.sessionContext.timeDiff(
              data.startTime,
              data.endTime
            ),
          });
        } else {
          // return (finalSendData[data.id] = null);
        }
      });

      try {
        let updatedURL;
        if (type) {
          updatedURL = `${urls.assessment.submit_assessment}`;
        } else {
          updatedURL = `${urls.workspace.submitQuiz}`;
        }
        // let res = await post({
        //   url: `${updatedURL}?result_id=${
        //     startQuizData.data
        //   }&time_spent=40&submit_type=save&quizResponse=${JSON.stringify(
        //     finalSendData
        //   )}`,
        // });
        let payload = {
          result_id: startQuizData.data,
          time_spent: 40,
          submit_type: "save",
          quizResponse: JSON.stringify(finalSendData),
        };
        let res = await post({
          contentType: "application/json",
          isUrlEncoded: true,
          url: updatedURL,
          data: payload,
        });

        if (res) {
          // res = JSON.parse(res);

          if (res.status === "success") {
            this.setState((state) => ({
              ...state,
              startQuiz: false,
            }));
          }
        } else {
          throw "lesson player track error";
        }
      } catch (e) {
        console.log(e);
      }
    },
    getWorkspaceData: async (userState) => {
      const { post, userData } = this.props.sessionContext;
      const { sharedbyUserPuid, tab } = this.state;
      let sharedId = userState?.sharedby_user_puid || "";
      if (!userState && tab.includes("shared")) {
        sharedId = sharedbyUserPuid;
      }
      const selectedFolderData = JSON.parse(
        localStorage.getItem("workspace-state")
      )?.workspaceData?.selectedFolder;
      this.state._setState((state) => ({
        ...state,
        workspaceData: {
          ...state.workspaceData,
          data: {
            ...state.workspaceData.data,
            lessons: [],
            assignments: [],
          },
          selectedFolder: {
            product_id:
              userState?.productId || selectedFolderData?.product_id || null,
          },
        },
        sharedbyUserPuid: sharedId,
      }));
      let payload;
      if (!userState) {
        payload = {
          user_puid: userData.userPuid,
        };
        // if (this.props.sessionContext.contentGrade) {
        //   payload.product_id = this.props.sessionContext.contentGrade;
        // }
      } else if (!userState.productId) {
        payload = {
          user_puid: userData.userPuid,
        };
      } else if (userState.level === 0) {
        payload = {
          user_puid: userData.userPuid,
          product_id: userState.productId,
        };
      } else if (userState.level === 1) {
        payload = {
          user_puid: userData.userPuid,
          product_id: userState.productId,
          unit_id: userState.unit_id,
          is_custom_unit_folder: userState.is_custom_unit_folder,
        };
      } else if (userState.level === 2) {
        payload = {
          user_puid: userData.userPuid,
          product_id: userState.productId,
          unit_id: userState.unit_id,
          is_custom_unit_folder: userState.is_custom_unit_folder,
          subunit_id: userState.sub_unit_id,
          is_custom_subunit_folder: userState.is_custom_subunit_folder,
        };
      } else if (userState.level === 3) {
        payload = {
          user_puid: userData.userPuid,
          product_id: userState.productId,
          unit_id: userState.unit_id,
          is_custom_unit_folder: userState.is_custom_unit_folder,
          subunit_id: userState.sub_unit_id,
          is_custom_subunit_folder: userState.is_custom_subunit_folder,
          level3_id: userState.level3_id,
          is_custom_level3_folder: userState.is_custom_level3_folder,
        };
      } else if (userState.level === 4) {
        //to show the shared lesson
        payload = {
          user_puid: userData.userPuid,
          product_id: userState.productId,
          sharedby_user_puid: userState.sharedby_user_puid,
        };
      }
      this.props.sessionContext.startSpinner();
      let res = await post({
        method: "GET",
        url: `${urls.workspace.workspaceData}?${qs.stringify(payload)}`,
        data: undefined,
      });
      this.props.sessionContext.stopSpinner();
      if (res) {
        if (res.status === "success") {
          if (!userState) {
            this.state._setState((state) => ({
              ...state,
              workspaceData: {
                ...res.data,
                selectedFolder: res.data ? res.data.data : {},
              },
              isGetAll: false,
            }));
            return res.data;
          } else {
            if (res.data && res.data.data) {
              this.state._setState(
                (state) => ({
                  ...state,
                  workspaceData: {
                    ...state.workspaceData,
                    data: res.data.data,
                  },
                }),
                () => {
                  // console.log(this.state);
                }
              );
              return res.data;
            } else {
              this.state._setState(
                (state) => ({
                  ...state,
                  workspaceData: {
                    ...state.workspaceData,
                    data: {
                      ...state.workspaceData.data,
                      lessons: res.data
                        ? res.data.data
                          ? res.data.data.lessons
                          : null
                        : null,
                    },
                  },
                }),
                () => {
                  console.log(this.state);
                }
              );
            }
            return res.data;
          }
        } else {
          this.state._setState((state) => ({
            ...state,
            workspaceData: {
              ...state.workspaceData,
              // selectedFolder: res.data ? res.data.data : {},
              data: {
                ...state.workspaceData.data,
                lessons: [],
                assignments: [],
              },
            },
          }));
          return res.data;
          // this.props.sessionContext.showNotification({
          //   message: res.message,
          //   type: "error",
          // });
          // throw res.message;
        }
      } else {
        throw "lesson player track error";
      }
    },
    shareLessonFromURL: async (id) => {
      const { post, userData } = this.props.sessionContext;
      let payload = {
        user_puid: userData.userPuid,
        id: id,
      };
      try {
        let res = await post({
          method: "POST",
          contentType: "application/json",
          isUrlEncoded: true,
          url: `${urls.lesson.shareLessonFromURL}`,
          data: payload,
        });

        if (res) {
          if (res.status === "success") {
            this.state._setState((state) => ({
              ...state,
              shareLessonData: res.data,
              isLessonUrlnotValid: false,
            }));
            return res.data;
          } else {
            //TODO
            // this.props.sessionContext.showNotification({
            //   message: res.message,
            //   type: "error",
            // });
            // throw res.message;
            this.state._setState((state) => ({
              ...state,
              isLessonUrlnotValid: true,
              invalidUrlmsg: res.message,
            }));
            return res.data;
          }
        } else {
          throw "Dashboard error";
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    getAllLessons: async (data) => {
      const { post, userData } = this.props.sessionContext;
      let payload = {
        user_puid: userData.userPuid,
        ...data,
      };
      try {
        this.state._setState((state) => ({
          ...state,
          workspaceData: {
            ...state.workspaceData,
            data: {
              ...state.workspaceData.data,
              lessons: [],
              assignments: [],
            },
          },
        }));
        let res = await post({
          method: "GET",
          url: `${urls.workspace.getAllLessons}?${qs.stringify(payload)}`,
          data: undefined,
        });

        if (res) {
          if (res.status === "success") {
            this.state._setState(
              (state) => ({
                ...state,
                workspaceData: {
                  ...state.workspaceData,
                  data: res.data.data,
                  selectedFolder: {
                    product_id:
                      state.workspaceData?.selectedFolder?.product_id ||
                      state.workspaceData?.selectedFolder,
                  },
                },
                isGetAll: true,
              }),
              () => {
                // console.log(this.state);
              }
            );
          } else {
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "error",
            });
            throw res.message;
          }
        } else {
          throw "Dashboard error";
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    addFolder: async ({ folderName, parentFolderId, parentFolderType }) => {
      const { post, userData, contentGrade } = this.props.sessionContext;
      let productId = contentGrade.product_id;
      let folderId = parentFolderId;
      if (parentFolderType === "product") folderId = productId;
      let res = await post({
        url: `${urls.folder.createFolder}?user_puid=${userData.userPuid}&folder_name=${folderName}&product_id=${productId}&parent_folder_type=${parentFolderType}&parent_folder_id=${folderId}&sort_order=1`,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "success",
          });
          await this.state.getWorkspaceData();
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "lesson player track error";
      }
    },
    editFolder: async (data) => {
      const { post, userData } = this.props.sessionContext;

      data.user_puid = userData.userPuid;
      let res = await post({
        url: `${urls.folder.updateFolder}?${qs.stringify(data)}`,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "success",
          });
          await this.state.getWorkspaceData();
          return true;
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "lesson player track error";
      }
    },
    addNewLesson: async (data) => {
      const { post, userData, domParse, fileUpload } =
        this.props.sessionContext;
      const { workspaceData } = this.state;
      let iscustom_unit_folder =
        data.unit_id && data.unit_id !== "null"
          ? workspaceData.meta &&
            workspaceData.meta
              .find((product) => product.product_id == data.product_id)
              .folders.find((unit) => unit.unit_id == data.unit_id).is_custom
            ? "yes"
            : "no"
          : null;

      let iscustom_subunit_folder =
        data.unit_id &&
        data.subunit_id !== "null" &&
        data.subunit_id &&
        workspaceData.meta &&
        workspaceData.meta
          .find((product) => product.product_id == data.product_id)
          .folders.find((unit) => unit.unit_id == data.unit_id)
          .sub_folders.find(
            (sub_unit) => sub_unit.sub_unit_id == data.subunit_id
          ).is_custom;
      if (iscustom_subunit_folder) {
        iscustom_subunit_folder = "yes";
      } else {
        if (!data.subunit_id || data.subunit_id == "null") {
          iscustom_subunit_folder = null;
        } else {
          iscustom_subunit_folder = "no";
        }
      }
      let iscustom_level3_folder = null;
      iscustom_level3_folder =
        data.unit_id &&
        data.subunit_id &&
        data.level3_id &&
        data.level3_id !== "null"
          ? workspaceData.meta &&
            workspaceData.meta
              .find((product) => product.product_id == data.product_id)
              .folders.find((unit) => unit.unit_id == data.unit_id)
              .sub_folders.find(
                (sub_unit) => sub_unit.sub_unit_id == data.subunit_id
              )
              .level3_folders.find((l3) => l3.level3_id == data.level3_id)
              .is_custom
            ? "yes"
            : "no"
          : null;
      const {
        lesson_name = "null",
        lesson_objective = "null",
        total_time = 0,
        due_date = "null",
        product_id = "null",
        unit_id = "null",
        subunit_id = "null",
        resources = [],
        is_assignment = "null",
        level3_id = "null",
        instructions = "",
        enforce_order = false,
      } = data;
      let updatedResource = [];
      let resource;
      resources.map((res) => {
        resource = {
          item_id: res.id,
          resource_type_id: res.resource_type_id || res.type_id,
          time: res.time || 0,
          sort_order: res.sort_order || 0,
          remarks:
            res.selectedReadingLevel === "Spanish_url"
              ? "spanish"
              : res.remarks || null,
        };
        updatedResource.push(resource);
      });
      let isAssignment = null;
      if (is_assignment === "lesson") {
        isAssignment = "no";
      } else {
        isAssignment = "yes";
      }
      let tempData = {
        user_puid: userData.userPuid,
        lesson_name: lesson_name,
        lesson_objective: lesson_objective,
        instructions: instructions,
        total_time: total_time || "null",
        due_date: due_date,
        iscustom_unit_folder: iscustom_unit_folder,
        product_id: product_id,
        unit_id: unit_id,
        iscustom_subunit_folder: iscustom_subunit_folder,
        subunit_id: subunit_id,
        iscustom_level3_folder: iscustom_level3_folder,
        level3_id: level3_id,
        lesson_resources: JSON.stringify(updatedResource),
        is_assignment: isAssignment,
        enforce_order: enforce_order,
      };

      let formData = new FormData();
      formData.append("user_puid", tempData.user_puid);
      formData.append("lesson_name", tempData.lesson_name);
      formData.append("lesson_objective", tempData.lesson_objective);
      formData.append("total_time", tempData.total_time);
      formData.append("due_date", tempData.due_date);
      formData.append("instructions", tempData.instructions);
      formData.append("iscustom_unit_folder", tempData.iscustom_unit_folder);
      formData.append("product_id", tempData.product_id);
      formData.append("unit_id", tempData.unit_id);
      formData.append(
        "iscustom_subunit_folder",
        tempData.iscustom_subunit_folder
      );
      formData.append("subunit_id", tempData.subunit_id);
      formData.append(
        "iscustom_level3_folder",
        tempData.iscustom_level3_folder
      );
      formData.append("level3_id", tempData.level3_id);
      formData.append("lesson_resources", tempData.lesson_resources);
      formData.append("is_assignment", isAssignment);
      formData.append("enforce_order", enforce_order);

      let res = await fileUpload({
        url: `${urls.lesson.createLesson}`,
        data: formData,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "success",
          });
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "lesson player track error";
      }
    },
    updateLesson: async (data) => {
      const { fileUpload, userData } = this.props.sessionContext;
      let updatedResource = [];
      let resource;
      data.resources &&
        data.resources.map((res) => {
          resource = {
            id: res.id || null,
            item_id: res.item_id || res.id,
            resource_type_id: res.resource_type_id || res.type_id,
            time: res.time || 0,
            sort_order: res.sort_order || 0,
            remarks: res.remarks || null,
          };
          updatedResource.push(resource);
        });
      // let isAssignment=data.is_assignment?"yes":"no"
      let iscustom_unit_folder = data.unit_id
        ? data.iscustom_unit_folder
          ? "yes"
          : "no"
        : "null";
      let iscustom_subunit_folder =
        data.sub_unit_id && data.sub_unit_id !== "null"
          ? data.iscustom_subunit_folder
            ? "yes"
            : "no"
          : "null";
      let iscustom_level3_folder = data.level3_id
        ? data.iscustom_level3_folder
          ? "yes"
          : "no"
        : "null";
      let is_assignment = data.is_assignment ? "yes" : "no";

      let formData = new FormData();
      formData.append("user_puid", userData.userPuid);
      formData.append("lesson_id", data.lesson_id);
      formData.append("lesson_name", data.lesson_name);
      formData.append("lesson_objective", null);
      formData.append("total_time", data.total_time);
      formData.append("due_date", data.due_date);
      formData.append("instructions", data.instructions);
      formData.append("iscustom_unit_folder", iscustom_unit_folder);
      formData.append("product_id", data.product_id);
      formData.append("unit_id", data.unit_id);
      formData.append("iscustom_subunit_folder", iscustom_subunit_folder);
      formData.append("subunit_id", data.subunit_id);
      formData.append("iscustom_level3_folder", iscustom_level3_folder);
      formData.append("level3_id", data.level3_id);
      formData.append("lesson_resources", JSON.stringify(updatedResource));
      formData.append("is_assignment", is_assignment);
      formData.append("enforce_order", data.enforce_order);
      formData.append("move_lesson", "no");
      let res = await fileUpload({
        url: `${urls.lesson.updateLesson}`,
        data: formData,
      });
      // let res = await post({
      //   url: `${urls.lesson.updateLesson}${queryParamas}`,
      // });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "success",
          });
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "lesson player track error";
      }
    },
    moveLesson: async (data) => {
      const { fileUpload, userData } = this.props.sessionContext;
      data.user_puid = userData.userPuid;

      let formData = new FormData();
      formData.append("user_puid", data.user_puid);
      formData.append("lesson_id", data.lesson_id);
      formData.append("lesson_name", data.lesson_name);
      formData.append("lesson_objective", null);
      formData.append("total_time", data.total_time);
      formData.append("due_date", data.due_date);
      formData.append("instructions", JSON.stringify(data.instructions));
      formData.append("iscustom_unit_folder", data.iscustom_unit_folder);
      formData.append("product_id", data.product_id);
      formData.append("unit_id", data.unit_id);
      formData.append("iscustom_subunit_folder", data.iscustom_subunit_folder);
      formData.append("subunit_id", data.subunit_id);
      formData.append("iscustom_level3_folder", data.iscustom_level3_folder);
      formData.append("level3_id", data.level3_id);
      formData.append("lesson_resources", null);
      formData.append("is_assignment", data.is_assignment);
      formData.append("enforce_order", data.enforce_order);
      formData.append("move_lesson", "yes");
      let res = await fileUpload({
        url: `${urls.lesson.updateLesson}`,
        data: formData,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "success",
          });
          this.state.getWorkspaceData();
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Get Lesson Error";
      }
    },
    getLesson: async (data) => {
      const { post, userData } = this.props.sessionContext;
      let lesson_id = data.lesson_id;
      let isAssignment = data.isAssignment || null;

      let res = await post({
        method: "GET",
        url: `${urls.lesson.getLesson}?user_puid=${userData.userPuid}&lesson_id=${lesson_id}&isAssignment=${isAssignment}`,
        data: undefined,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            lessonUpdateData: res.data,
          }));
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Get Lesson Error";
      }
    },
    deleteLesson: async (data, showAll) => {
      const { post, userData } = this.props.sessionContext;
      let lesson_id = data.lessonId;
      let isAssignment = data.is_assignment;

      let res = await post({
        method: "DELETE",
        url: `${urls.lesson.deleteLesson}?user_puid=${userData.userPuid}&lesson_id=${lesson_id}&is_assignment=${isAssignment}`,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "success",
          });
          if (showAll) {
            let payload = {
              product_id:
                this.state.workspaceData.selectedFolder.product_id ||
                this.state.workspaceData.selectedFolder ||
                null,
              sharedby_user_puid: null, //TODO
            };
            await this.state.getAllLessons(payload);
          }
          await this.state.getWorkspaceData();
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Delete Lesson Error";
      }
    },
    getClasses: async (lesson_id) => {
      const { post, userData } = this.props.sessionContext;
      const payload = {
        user_puid: userData.userPuid,
        lesson_id: lesson_id,
      };

      let res = await post({
        url: `${urls.lesson.getClasses}?${qs.stringify(payload)}`,
        // url: `${urls.mygroups.getClasses}?${qs.stringify(payload)}`,
        method: "GET",
        data: undefined,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            classData: res.data,
          }));
          return res.data;
        } else {
          // this.props.sessionContext.showNotification({
          //   message: res.message,
          //   type: "error",
          // });
          throw res.message;
        }
      } else {
        throw "Delete Lesson Error";
      }
    },
    startLesson: async (data) => {
      const { post, userData } = this.props.sessionContext;
      data = { ...data, user_puid: userData.userPuid };
      this.state._setState((state) => ({
        ...state,
        StartLessonData: 0,
        // startLessonId: 0,
      }));
      let res = await post({
        url: `${urls.lesson.startLesson}?${qs.stringify(data)}`,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            startLessonId: res.data,
            StartLessonData: res.data,
          }));
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Start Lesson Error";
      }
    },
    previewLesson: async (data, type) => {
      const { post, userData } = this.props.sessionContext;
      data = { ...data, user_puid: userData.userPuid };
      this.state._setState((state) => ({
        ...state,
        StartLessonData: 0,
        // startLessonId: 0,
      }));
      let res = await post({
        url: `${urls.lesson.previewLesson}?${qs.stringify(data)}`,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            startLessonId: res.data,
            StartLessonData: res.data,
            isPrebuilt: type === "prebuilt" ? true : false,
          }));
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Start Lesson Error";
      }
    },
    getStudentGroups: async (groupid, lesson_id) => {
      const { post, userData } = this.props.sessionContext;
      const payload = {
        user_puid: userData.userPuid,
        group_puid: groupid,
        lesson_id: lesson_id,
      };

      let res = await post({
        method: "GET",
        url: `${urls.mygroups.getClassesAndUsers}?${qs.stringify(payload)}`,
        data: undefined,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            studentGroupData: res.data,
          }));
          return res.data;
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Delete Lesson Error";
      }
    },
    getFullGroupsData: async (classData, lesson_id) => {
      const groupsData = [];
      const groups = classData;

      await groups.forEach(async (group) => {
        // const subgroupsData = await this.state.getStudentGroups(
        //   group.group_puid,
        //   lesson_id
        // );
        const subgroupsData = null;
        // if (Object.keys(subgroupsData).length > 0) {
        const fullGroup = { ...group, ...subgroupsData };
        groupsData.push(fullGroup);
        this.state._setState((prev) => ({
          ...prev,
          fullGroupsData: [...prev.fullGroupsData, { ...fullGroup }],
        }));
        // }
      });
      return groupsData;
    },
    assignAssignment: async (data, type) => {
      const { post, userData } = this.props.sessionContext;
      const payload = {
        user_puid: userData.userPuid,
        lesson_id: data.lesson_id,
        group_puid: data.group_puid,
        assignment_no: data.assignment_no,
        assignment_details: JSON.stringify(data.assignment_details),
      };
      let res = await post({
        contentType: "application/json",
        isUrlEncoded: true,
        url: urls.lesson.assignAssignment,
        data: payload,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          if (type !== "prebuilt") await this.state.getWorkspaceData();
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "success",
          });
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Assign Assignment Error";
      }
    },
    assignGroups: async (data) => {
      const { post, userData } = this.props.sessionContext;
      const payload = {
        user_puid: userData.userPuid,
        ...data,
      };
      let res = await post({
        contentType: "application/json",
        isUrlEncoded: true,
        url: urls.lesson.assignMultipleClasses,
        data: payload,
      });

      if (res) {
        if (res.status === "success") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "success",
          });
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Assign Assignment Error";
      }
    },
    unAssign: async (data, type) => {
      const { post } = this.props.sessionContext;

      let res = await post({
        contentType: "application/json",
        isUrlEncoded: true,
        url: urls.lesson.unAssign,
        data: data,
      });

      if (res) {
        if (res.status === "success") {
          if (type !== "prebuilt") await this.state.getWorkspaceData();
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "success",
          });
          localStorage.setItem("isUnassigned", true);
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Assign Assignment Error";
      }
    },
    getLessonURLId: async (lesson_id) => {
      const { post, userData } = this.props.sessionContext;
      const payload = {
        user_puid: userData.userPuid,
        lesson_id: lesson_id,
      };
      let res = await post({
        contentType: "application/json",
        // method: "GET",
        isUrlEncoded: true,
        url: urls.lesson.getLessonURLId,
        data: payload,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          let lessonURLId =
            (res.data && res.data.length > 0 && res.data[0]) || null;
          this.state._setState({ lessonURLId: lessonURLId });
          return lessonURLId;
        } else {
          // this.props.sessionContext.showNotification({
          //   message: res.message,
          //   type: "error",
          // });
          throw res.message;
        }
      } else {
        throw "Assign Assignment Error";
      }
    },
    createLessonURLId: async (data) => {
      const { post, userData } = this.props.sessionContext;
      const payload = {
        user_puid: userData.userPuid,
        lesson_id: data.lesson_id,
        end_date: data.end_date || null,
        is_perpectual_expiry: data.perpetual,
        assignment_no: 1,
      };
      let res = await post({
        contentType: "application/json",
        // method: "GET",
        isUrlEncoded: true,
        url: urls.lesson.createLessonURLId,
        data: payload,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          let lessonURLId = res.data || null;
          this.state._setState({ lessonURLId: res.data });
          return lessonURLId;
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Assign Assignment Error";
      }
    },
    getSharedUsersForLesson: async (data, firstCall) => {
      const { post, userData } = this.props.sessionContext;
      const payload = {
        role_id: userData.role,

        shared_by_puid: userData.userPuid,
        ...data,
      };
      let res = await post({
        contentType: "application/json",
        // method: "GET",
        isUrlEncoded: true,
        url: urls.lesson.getSharedUsersForLesson,
        data: payload,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          // let lessonURLId = res.data || null;
          if (firstCall) {
            this.state._setState({ sharedUsersData: res.data });
          } else {
            this.state._setState({
              ...this.state,
              sharedUsersData: {
                ...this.state.sharedUsersData,
                data: res.data.data,
              },
            });
          }
          // return lessonURLId;
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Assign Assignment Error";
      }
    },
    shareLesson: async (data) => {
      const { post, userData } = this.props.sessionContext;
      const { sharedUsersData } = this.state;
      let user_puid = [];
      sharedUsersData.data
        .filter((c) => c.selected)
        .map((v) => user_puid.push(v.user_puid));
      const payload = {
        user_puid_list: user_puid,
        shared_by_puid: userData.userPuid,
        permission: 100,
        ...data,
      };
      let res = await post({
        contentType: "application/json",
        // method: "GET",
        isUrlEncoded: true,
        url: urls.lesson.shareLesson,
        data: payload,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "success",
          });
          return true;
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Assign Assignment Error";
      }
    },
    getResources: async (data = {}) => {
      try {
        this.props.sessionContext.startSpinner();
        const { post, userData } = this.props.sessionContext;

        data = {
          product_id: data.product_id || "null",
          unit_id: data.unit_id || "null",
          subunit_id: data.sub_unit_id || "null",
          level3_id: data.level3_id || "null",
        };
        data.user_puid = userData.userPuid;
        data.is_learnosity_enabled = this.state?.isLeanosity || false;
        data.user_puid = userData.userPuid; //TODO remove hardcoded ID
        let res = await post({
          method: "GET",
          url: `${urls.content.getItems}?${qs.stringify(data)}`,
          data: undefined,
        });

        if (res) {
          // res = JSON.parse(res);

          if (res.status === "success") {
            this.state._setState((state) => ({
              ...state,
              itemData: res.data,
            }));
            // let isLevel3=false;
            //   if (
            //     res.data.categories[0].categories[0] &&
            //     res.data.categories[0].categories[0].categories
            //   ) {
            //     isLevel3 = true;
            //   } else {
            //     isLevel3 = false;
            //   }
            //     this.state._setState((state) => ({
            //       ...state,
            //       isLevel3,
            //     }));
          } else {
            this.state._setState((state) => ({
              ...state,
              itemData: {},
            }));
            throw res.error;
          }
        } else {
          this.state._setState((state) => ({
            ...state,
            itemData: {},
          }));
          throw "Content error";
        }
        this.props.sessionContext.stopSpinner();
      } catch (err) {
        this.state._setState((state) => ({
          ...state,
          itemData: {},
        }));
        this.props.sessionContext.stopSpinner();
        throw err;
      }
    },
    getunitData: async () => {
      const { post, userData } = this.props.sessionContext;
      this.state._setState((state) => ({
        ...state,
        unitData: [],
      }));
      let res = await post({
        method: "GET",
        url: `${urls.content.getUnits}?user_puid=${userData.userPuid}`, //TODO remove hardcoded ID
        data: undefined,
      });
      if (res) {
        if (res.status === "success") {
          let isLevel3 = false;
          let isLeanosity = res.data.data?.find(
            (d) => d.id == res.data.default_data.product_id
          ).is_learnosity_enabled;
          if (res.data.data[0]?.categories[0]?.sub_categories[0]?.level3) {
            isLevel3 = true;
          } else {
            isLevel3 = false;
          }
          this.state._setState((state) => ({
            ...state,
            unitData: res.data,
            isLevel3,
            isLeanosity,
          }));
        } else {
          throw res.error;
        }
      } else {
        throw "get Unit error";
      }
    },
    duplicateLesson: async (data) => {
      try {
        const { post, userData, showNotification } = this.props.sessionContext;
        const { workspaceData } = this.state;
        data.user_puid = userData.userPuid;
        let res = await post({
          method: "POST",
          url: `${urls.lesson.duplicateLesson}?${qs.stringify(data)}`,
        });

        if (res) {
          if (res.status === "success") {
            let prodId =
              workspaceData.selectedFolder.product_id ||
              workspaceData.selectedFolder ||
              null;
            if (this.state.tab.includes("shared")) {
              await this.state.getWorkspaceData({
                productId: prodId,
                sharedby_user_puid: this.state?.sharedbyUserPuid,
                level: 4,
              });
            } else {
              if (this.state.isGetAll) {
                let payload = {
                  product_id: prodId,
                  sharedby_user_puid: null,
                };
                await this.state.getAllLessons(payload);
              }
            }
            await this.state.getWorkspaceData();

            showNotification({ message: res.message, type: "success" });
          } else {
            let msg = `Unable to Duplicate.${
              data.type === "lesson" ? "Live lesson" : "Assignment"
            } already exists`;
            showNotification({ message: msg, type: "error" });
            throw res.error;
          }
        } else {
          throw "Content error";
        }
      } catch (err) {
        this.state._setState((state) => ({
          ...state,
          itemData: {},
        }));
        throw err;
      }
    },

    getPrebuiltAssignmnets: async (data, selectedPrebuiltFolder) => {
      const { post } = this.props.sessionContext;

      try {
        let res = await post({
          method: "POST",
          contentType: "application/json",
          isUrlEncoded: true,
          url: `${urls.workspace.getPrebuiltAssignmnets}`,
          data: data,
        });

        if (res) {
          if (res.status === "success") {
            this.state._setState((state) => ({
              ...state,
              selectedPrebuiltFolder,
              prebuiltSelectedFolder: { ...selectedPrebuiltFolder },
              workspaceData: {
                ...state.workspaceData,
                selectedFolder: {
                  ...state.workspaceData.selectedFolder,
                  product_id: selectedPrebuiltFolder?.product_id,
                },
              },
            }));
            return res.data;
          } else {
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "error",
            });
            throw res.message;
          }
        } else {
          throw "Prebuilt error";
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    getLessonBlocks: async (data, selectedPrebuiltFolder) => {
      const { post } = this.props.sessionContext;

      try {
        let res = await post({
          method: "POST",
          contentType: "application/json",
          isUrlEncoded: true,
          url: `${urls.content.getLessonBlocks}`,
          data: data,
        });

        if (res) {
          if (res.status === "success") {
            this.state._setState((state) => ({
              ...state,
              selectedPrebuiltFolder,
              prebuiltSelectedFolder: { ...selectedPrebuiltFolder },
              workspaceData: {
                ...state.workspaceData,
                selectedFolder: {
                  ...state.workspaceData.selectedFolder,
                  product_id: selectedPrebuiltFolder?.product_id,
                },
              },
            }));
            return res.data;
          } else {
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "error",
            });
            throw res.message;
          }
        } else {
          throw "svl error";
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
  };

  render() {
    return (
      <WorkspaceContext.Provider value={this.state}>
        {this.props.children}
        <Persist
          name="workspace-state"
          data={this.state}
          debounce={500}
          onMount={(data) => this.setState(data)}
        />
      </WorkspaceContext.Provider>
    );
  }
}

export default withSessionContext(WorkspaceContextProvider);
